import { React, useState,useEffect } from 'react';
import HomePage from './pages/Home';
import BeatLoader from "react-spinners/BeatLoader";
import "./App.css";




const App = () => {

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }, [])


  

    return <div>
        {


        loading ?
        <div className="Boom">
        <BeatLoader color={"#000000"} loading={loading} css="" size={10} />
        {/* <div className="loader-message">BOOM BOI</div> */}
        </div>
        :


        <HomePage />
}
    </div>;
        
};



export default App;
