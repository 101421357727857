import React from "react";
import "./header.css";

const Header = () => {
  return (
    <div className="max-width-header header">
      <img
        // src="https://scontent-dub4-1.xx.fbcdn.net/v/t1.18169-9/23561320_2034500900103161_7457537510210243948_n.jpg?_nc_cat=107&ccb=1-5&_nc_sid=174925&_nc_ohc=zIZqhklxhR0AX938yvf&_nc_ht=scontent-dub4-1.xx&oh=00_AT88cfGX0B9yhlxE7832-P4p4wdiWNz4uoZ0pR2YhEBJ_Q&oe=6230B543"
// https://images.squarespace-cdn.com/content/v1/5be0a12d8f51301dd00edf22/1541448125169-7IPAJAETH2RBEBQ5GW7Y/Flipside+logo+jpeg+serious+burgers.jpg?format=1500w
        // src="https://scontent-dub4-1.xx.fbcdn.net/v/t31.18172-8/22218567_1902826153082339_4398831589956361814_o.jpg?_nc_cat=103&ccb=1-5&_nc_sid=174925&_nc_ohc=MoVRS_LeZpsAX_Z5GKi&_nc_ht=scontent-dub4-1.xx&oh=00_AT8tOIz3yoFnYchGyySTqUoCYbD6J4FXPXnUTRUrcaamLA&oe=622A8C66"

        src="https://images.squarespace-cdn.com/content/v1/5be0a12d8f51301dd00edf22/1541448125169-7IPAJAETH2RBEBQ5GW7Y/Flipside+logo+jpeg+serious+burgers.jpg?format=1500w"
        alt="logo"
        className="header-logo"
      />
     
    </div>
  );
};

export default Header;
