import React, { useState } from "react";
import FilterItem from "./filterItem";
import "./filters.css";
import ScrollSpy from "./ScrollSpy";
import FancyButton from "../FancyButton";
import { createRef } from "react";
// import ScrollspyNav from "react-scrollspy-nav";

const Filters = ({ filterList }) => {
  return (
    <div className="filters">
      <ScrollSpy>
        {filterList &&
          filterList.map((filter, index) => {
            return (
              <FancyButton
                href={`#${filter.replace(/\s+/g, "")}`}
                key={`${filter.replace(/\s+/g, "")}-${index}`}
                // onClick={(event)=>{
                //   event.preventDefault();
                //   console.log(event.target.hash);
                //   window.location.hash = event.target.hash;
                // }}
                text={filter}
                ref={createRef()}
              />
            );
          })}
      </ScrollSpy>
    </div>
  );
};

export default Filters;
