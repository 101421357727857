import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCdQBU3L7JKZsMBtabRyLTOhHMsrp0hEwE",
  authDomain: "space-9e5b1.firebaseapp.com",
  databaseURL: "https://space-9e5b1.firebaseio.com",
  projectId: "space-9e5b1",
  storageBucket: "space-9e5b1.appspot.com",
  messagingSenderId: "1082976033424",
  appId: "1:1082976033424:web:dbdc749295d93b2cd05615",
  measurementId: "G-BNH662WLB8"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
