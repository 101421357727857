import React, { useState, useEffect } from "react";
import ExploreSection from "../exploreSection";
import Filters from "../filters";
import "./delivery.css";
import { db } from "../../../../src/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth";
// import PacmanLoader from "react-spinners/PacmanLoader";





const Delivery = () => {
  const [mappedFood, setMappedFood] = useState([]);
  const [resturantFoodItems, setResturantFoodItems] = useState([]);
  const [foodTypes, setFoodTypes] = useState([]);
  const [foodItems, setFoodItems] = useState([]);


  useEffect(() => {
    const getResturantData = async () => {


      const queryString = window.location.search;

      const urlParams = new URLSearchParams(queryString);


      const product = urlParams.get('name')
      console.log(product);


      const resturantCollectionRef = collection(db, product);
      const data = await getDocs(resturantCollectionRef);
      setResturantFoodItems(
        data.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    };
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        getResturantData();
        // Signed in..
      })
      .catch((error) => {
        console.log("---Error in fetching firebase records----", error);
      });
  }, []);



  useEffect(() => {
    foodItems.map((v, i) => {
      return resturantFoodItems.map((e, x) => {
        if (v.type === e.type) {
          v.dataArray.push(e);
        }
      });
    });
    setMappedFood(foodItems);
  }, [foodItems]);

  useEffect(() => {
    const array2 = resturantFoodItems.map((x) => x.type);
    setFoodTypes(["All", ...new Set(array2)]);
  }, [resturantFoodItems]);

  useEffect(() => {
    var resturantItem = foodTypes.filter((item, index) => index !== 0);
    var filteredResturantItem = resturantItem.map((item, index) => {
      return {
        type: item,
        dataArray: [],
      };
    });
    setFoodItems(filteredResturantItem);


  }, [foodTypes]);




  return (

    <div>
      <div>
        <div className="max-width-header">
          <Filters filterList={foodTypes} sticky="top" />
        </div>

        <div style={{ overflowY: "auto" }}>
          <ExploreSection
            list={mappedFood}
            collectionName="Delivery Restaurants in Sligo"
          />
        </div>
      </div>

    </div>


  );
};

export default Delivery;
