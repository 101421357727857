import React from "react";
import ExploreCard from "./exploreCard";
import "./exploreSections.css";

const ExploreSection = ({ list, collectionName }) => {
  return (
    <div className="max-width explore-section" style={{ paddingTop: "100px" }}>
      <div style={{ display: "flex", flexDirection: 'column'}}>
        {list.map((restaurant) => {
          let type = restaurant.type;
          return (<React.Fragment key={type}>
           
            <h2 style={{marginLeft:"20px"}}>{type=="All"?"":type}</h2>
            <section id={type.replace(/\s+/g, '')} className="section-item">
              <ExploreCard restaurant={restaurant} />
            </section>
          </React.Fragment>);
        })}
      </div>
    </div>
  );
};
export default ExploreSection;