import React from 'react';
import "./diningout.css";


const DiningOut = () => {
  return <div>
      this is diningout
  </div>;
};

export default DiningOut;
