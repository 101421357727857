import React from 'react';
import "./nightlife.css";

const NightLife = () => {
  return <div>
      This is NightLife
  </div>;
};

export default NightLife;
