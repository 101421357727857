import React, { useState } from "react";
import Footer from "../../components/common/footer";
import Header from "../../components/common/header";
import TabOptions from "../../components/common/tabOptions";
import Delivery from "../../components/common/delivery";
import DiningOut from "../../components/diningOut";
import Nightlife from "../../components/nightlife";


const HomePage = () => {
  // const [users, setUsers] = useState([]);
  // const usersCollectionRef = collection(db, "The1996Belfry")

  // useEffect(() => {
  //     const getUsers = async () => {
  //         const data = await getDocs(usersCollectionRef);
  //         setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

  //         console.log("DATA: ",data)
  //     }

  //     const auth = getAuth();
  //     signInAnonymously(auth)
  //         .then(() => {
  //             // Signed in..

  //             console.log("BOOM")
  //             getUsers()
  //         })
  //         .catch((error) => {
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // ...
  //         });
  // }, [])

  const [activeTab, setActiveTab] = useState("Delivery");
  return (
    <div>
      <Header />

      {/* <div className="App">
      {users.map((user) => {
        return <div><h1>Name: {user.type}</h1></div>
      })}
    </div> */}
      <TabOptions activeTab={activeTab} setActiveTab={setActiveTab} />
      {/* Diff Screen */}

      {getCorrectScreen(activeTab)}
      <Footer />
    </div>
  );
};

const getCorrectScreen = (tab) => {
  switch (tab) {
    case "Delivery":
      return <Delivery />;
    case "Dining Out":
      return <DiningOut />;
    case "Nightlife":
      return <Nightlife />;
    default:
      return <Delivery />;
  }
};

export default HomePage;
