import { forwardRef } from "react";
// import styles from './FancyButton.module.css';
import "./filters/filters.css";

const FancyButton = forwardRef(({ href, text, onClick }, ref) => (
  <a
    ref={ref}
    href={href === "#All" ? "#" : href}
    onClick={onClick}
    className="filter-item"
  >
    {text}
  </a>
));
export default FancyButton;
