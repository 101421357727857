import React, { useState, useEffect } from "react";
//import { useEffect } from "react/cjs/react.development";
import "./exploreCard.css";
import '@google/model-viewer';


const ExploreCard = ({ restaurant, i }) => {

  useEffect(() => {



  }, [restaurant])




  return (
    <>
      {restaurant.dataArray.map((e, v) => {
        return (
          <div
            key={`${e.type}_${e.id}`}
            // id={e.type}
            className={`explore-card cur-po ${i < 3 ? "explore-card-first" : ""
              }`}
          >
            <div>

            </div>
            <div className="explore-card-cover">
              <img
                src={e.imageUrl}
                className="explore-card-image"
                alt={e.Name}
              />

              {/* AR IMAGE LINKED HERE  */}
              
              {e.arPresent && 
                <div className="delivery-time">

                  <button className="ar-btnn" onClick={() => {

                    var uagent = navigator.userAgent.toLowerCase();

                    if (uagent.search("iphone") || uagent.search("iPad") > -1)
                    {
                      alert('true');
                      window.open("https://web-test-two.s3.eu-west-1.amazonaws.com/Nachos.usdz", "www.google.ie");

                      // href="https://roxanajula.com/wp-content/uploads/2020/09/Adidas_Originals_Stan_Smith.usdz"

  

                    } 
                    else{
                      alert('ANDROID BOI');

                     
                      // href="https://roxanajula.com/wp-content/uploads/2020/09/Adidas_Originals_Stan_Smith.usdz"

                    }
                      

                  }}

                  
                    >
                    {/* AR */}
                    <img 
                    className="imgz"
                    src="https://lillies-space.s3.eu-west-1.amazonaws.com/arkit.png"/>
                  </button>

                  {/* <img
                src={"https://www.hackingwithswift.com/uploads/arkit.jpg"}
                // className="explore-card-image"
                className="ar-image"
                alt={e.Name}
              />  */}

                  {/* AR IMAGE */}
                  {/* 
                  <model-viewer
                    Style="display:none;"
                    className="arbtn"
                    // src= "/Nachos.glb"
                    ios-src="https://web-test-two.s3.eu-west-1.amazonaws.com/Nachos.usdz"
                    camera-controls
                    ar> */}



                  {/* <button slot="ar-button" className="ar-button">
                      VIEW
                    </button> */}

                  {/* <button slot="ar-button" style="background-color: white; border-radius: 4px; border: none; position: absolute; top: 16px; right: 16px; ">
          👋 Activate AR
        </button> */}


                  {/* </model-viewer> */}
                </div>
              }
            


            </div>
            <div className="res-row">


              <div className="res-name">{e.Name}</div>
            </div>
            <div className="res-row res-row-textflow">
              {e.Description}
              {e.price}


            </div>

          </div>

        );
      })}
    </>
  );
};

export default ExploreCard;